import * as React from "react";
import {useCallback, useState, useEffect} from 'react';
import {Button, Center, Text , Stack, Textarea, Box, VStack, StackDivider, Heading} from "@chakra-ui/react";
import {FormControl, FormLabel} from "@chakra-ui/react";
import {
  useParams,
} from "react-router-dom";
import { getCzujnik, getOdczyt, saveOdczyt } from "../components/localStorage";
import SlideRule from 'react-slide-rule';
import Switch from "react-switch";
import { useNavigate } from 'react-router-dom';

const STYLE = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};
 
 function Czujnik() {

  const navigate = useNavigate();

  const { id } = useParams();
  const [czujnik, setCzujnik] = useState(getCzujnik(id));
  const [odczyt, setOdczyt] = useState(getOdczyt(id));
  const [isChoosen, setIsChoosen] = useState(false);
  const [isOverLimit, setOverLimit] = useState(false);
  const [uwagi, setUwagi] = useState('');
  const [switchValue, setSwitchValue] = useState(false);
  const [value, setValue] = useState((parseInt(czujnik.zakres) + parseInt(czujnik.wartosc)) / 2);

  useEffect(() => {

    setCzujnik(getCzujnik(id));
    if (odczyt && odczyt.data) {
      if (odczyt.data.switch !== undefined) {
        setSwitchValue(odczyt.data.switch)
      }
      if (odczyt.data.overlimit !== undefined) {
        setOverLimit(odczyt.data.overlimit)
      }
      if (odczyt.data.value !== undefined) {
        setValue(odczyt.data.value)
      }
    }

  }, [id, odczyt]);



  let handleButtonOverLimit = () => {

    setIsChoosen(true);
    setOverLimit(true);
    setUwagi('Odczyt poza limitem. ')

  };

  let handleButtonInLimit = () => {

    setIsChoosen(true);
    setOverLimit(false);

  };

  let handleInputChange = (e) => {
    let inputValue = e.target.value
    setUwagi(inputValue)
  }

  let handleSwitchChange = (e) => {
    //setSwitchValue(e.target.checked)
    setSwitchValue(e)
  }

  let handleSwitchSave = (e) => {

    let czas = '2024-11-19 21:45:00';

    let data = {};

    if (czujnik.zakres == 'nok') {

      data = {
        switch: switchValue,
        uwagi: uwagi,
        czas: czas
      }


    } else if (isOverLimit) {

      data = {
        overlimit: true,
        uwagi: uwagi,
        czas: czas
      }


    } else {

      data = {
        value: value,
        uwagi: uwagi,
        czas: czas
      }

    }

    saveOdczyt(id, data);

    navigate('/odczyty');
  }

  const [ref, setRef] = useState(null);
  const widthRule = ref?.offsetWidth;

  const handleRuleInputChange = useCallback(
    (e) =>
      setValue(Number(e.target.value)),
    []
  );
  const handleRuleInputBlur = useCallback(
    (e) =>
      setValue(Math.max(czujnik.wartosc, Math.min(Number(e.target.value), czujnik.zakres))),
    []
  );

  let czujnikSwitch = <>
    <Box textAlign="center" p={2} my={3} bg='blue.100' rounded='md'>
    <FormLabel htmlFor='switch' pb={1} fontSize={20} textAlign="center">
      Czujnik działa poprawnie?
    </FormLabel>
    <Switch id='switch' size='lg' height={40} width={80} offColor='#ff0000' onChange={handleSwitchChange} checked={switchValue} />
    </Box>
    <FormLabel htmlFor='uwagi' pb={1} mt={3}>
      Uwagi
    </FormLabel>
    <Textarea
      id='uwagi'
        value={uwagi}
        onChange={handleInputChange}
        placeholder='Wpisz swoje uwagi...'
        size='lg'
      />      
  </>;

  let czujnikRuleInLimit = <>
<div ref={setRef} className="example-basic example-input-element">
  <p>{czujnik.jednostka}</p>
  <div className="arrow" />
    <input
        value={value}
        onChange={handleRuleInputChange}
        onBlur={handleRuleInputBlur}
        step={.5}
        min={czujnik.wartosc}
        max={czujnik.zakres}
        type="number"
      />
      <SlideRule
        value={value}
        onChange={setValue}
        min={czujnik.wartosc}
        max={czujnik.zakres}
        step={.5}
        width={widthRule}
      />
</div>
    <FormLabel htmlFor='uwagi' pb={1} mt={3}>
      Uwagi
    </FormLabel>
    <Textarea
      id='uwagi'
        value={uwagi}
        onChange={handleInputChange}
        placeholder='Wpisz swoje uwagi...'
        size='lg'
      />
  </>;

let czujnikRuleOverLimit = <>
<Text bg='red' p={2} my={3} align='center' fontSize={20} color='white'><strong>ODCZYT POZA LIMITEM</strong></Text>
<FormLabel htmlFor='uwagi' pb={1} mt={3}>
      Uwagi
    </FormLabel>
<Textarea
  id='uwagi'
    value={uwagi}
    onChange={handleInputChange}
    placeholder='Wpisz swoje uwagi...'
    size='lg'
  />
</>;

  return (
    <>

    <Heading as='h1' textAlign="center" my={3}>
      Odczyt czujnika: {id}
    </Heading>

    {czujnik && <>
      <Text textAlign="center" mt={3}>Nazwa punktu: <strong>{czujnik.nazwaPunktu}</strong></Text>
      <Text textAlign="center" mt={1}>Lokalizacja: <strong>{czujnik.lokalizacjaPunktu}</strong></Text>
      <Text textAlign="center" mt={1}>Kontrola: <strong>{czujnik.kontrola}</strong></Text>

      </>
    }
    
    {(czujnik.zakres == 'nok')?
    <>{czujnikSwitch}
    <Box textAlign="center" p={2} my={3}>
    <Button size='lg' colorScheme='blue' onClick={handleSwitchSave}>Zapisz odczyt</Button>
    </Box>
    </>
    :
    isChoosen?
      <>
      {!isOverLimit ? czujnikRuleInLimit : czujnikRuleOverLimit}
      <Box textAlign="center" p={2} my={3}>
        <Button size='lg' colorScheme='blue' onClick={handleSwitchSave}>Zapisz odczyt</Button>
      </Box>
      </>
      :
      <>
      <Box textAlign="center" p={2} mt={3}>
      <Button size='lg' colorScheme="green" onClick={handleButtonInLimit}>Wartość w zakresie ({czujnik.wartosc}-{czujnik.zakres} {czujnik.jednostka})</Button>
      </Box>
      <Box textAlign="center" p={2} my={1}>
      <Button size='lg' colorScheme="red" onClick={handleButtonOverLimit}>Wartość poza zakresem</Button>
      </Box>
      </>
    }


    </>
  )

}

export default Czujnik;