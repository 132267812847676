import {useState} from 'react';
import { NavLink } from "react-router-dom"


import {
  Stack,
  Button,
  Box
} from '@chakra-ui/react'
import NfcButton from '../components/NfcButton';
import { deleteOdczyty } from '../components/localStorage';

function Home() {


  const [nfcText, setnfcText] = useState<any>('');
  

  const abortController = new AbortController();
  abortController.signal.onabort = event => {
    // All NFC operations have been aborted.
    
  };



    return (<><Box textAlign="center" p={2} my={5}>
      
        <NfcButton></NfcButton>

    <br></br>{(nfcText=='aa') && <Button onClick={() => {
      abortController.abort();
      console.log('Przerwano skanowanie');
      setnfcText(nfcText + ';Przerwano skanowanie');
    }}>Przerwij skanowanie NFC New</Button>}
    <br></br><NavLink to={'/odczyty/'}><Button mt={5} size='lg' colorScheme='blue' >Odczyty</Button></NavLink>
    <br></br><NavLink to={'/czujniki/'}><Button mt={5} size='lg' colorScheme='teal' >Czujniki</Button></NavLink>
    <br></br><br></br><Button mt={5} size='lg' colorScheme='red' onClick={() => {
    deleteOdczyty();
  }}>Usuń Odczyty</Button>
    </Box>
    <Box >{nfcText}</Box></>
);
  };
  
  export default Home;