import React, { useEffect, useState } from "react";
import {Accordion, Heading, Box, Button, Text } from "@chakra-ui/react";
import {getOdczyty} from "../components/localStorage";
import OdczytItem from "../components/OdczytItem";
import NfcButton from '../components/NfcButton';

import {
  ArrowForwardIcon
} from '@chakra-ui/icons'

export default function Odczyty() {

  const [odczytyItems, setOdczytyItems] = useState(getOdczyty());

  useEffect(() => {

    //setOdczytyItems(getOdczyty());

  });

  let odczytyItemsFromNewest = [];

    if (odczytyItems) {
    for (let i = odczytyItems.punkty.length - 1; i >=0; i-- ) {
      odczytyItemsFromNewest.push(odczytyItems.punkty[i]);
    }
  }

  const [nfcText, setnfcText] = useState('');

  const abortController = new AbortController();
  abortController.signal.onabort = event => {
    // All NFC operations have been aborted.
    
  };

  const nfc = () => {
    /*
    if ('NDEFReader' in window) {
      const ndef = new NDEFReader();

      var textTemp = '';

      ndef.scan({signal: abortController.signal}).then(() => {
        console.log("Scan started successfully.");
        textTemp += ';Scan started successfully.';
        setnfcText(textTemp);
        ndef.onreading = event => {

          const decoder = new TextDecoder();
          
          const message = event.message;
          for (const record of message.records) {
            console.log("Record type:  " + record.recordType);
            console.log("MIME type:    " + record.mediaType);
            console.log("Record id:    " + record.id);
  
            //textTemp += ';' + record.recordType;
            textTemp += ';' + record.recordType + ': ' + decoder.decode(record.data);
            setnfcText(nfcText + ';' + textTemp);
            switch (record.recordType) {
              case "text": 
                if (record.data !== undefined) {
                  //textTemp += ';' + record.data;
                }
                break;
              case "url":
                // TODO: Read URL record with record data.
                break;
              default:
                // TODO: Handle other records with record data.
            }
            

            
          }

          abortController.abort();
        };
      }).catch((error: any) => {
        console.log(`Error! Scan failed to start: ${error}.`);
        textTemp += ';Error! Scan failed to start';
        setnfcText(textTemp);
      });

      



    } else {
      console.log('a:'+nfcText);
      setnfcText(nfcText + ';Brak NFC');
    }

    */
    
  }


  return (
    <>

  <Heading as='h1' textAlign="center" my={5}>
    Odczyty
  </Heading>
  <Box textAlign="center" p={2} my={5}>
    <NfcButton></NfcButton>
  </Box>
  { odczytyItems &&
  <Accordion allowToggle>
      {odczytyItemsFromNewest.map((item, index) => (
        <OdczytItem key={item.id} item={item}></OdczytItem>

      ))}
      </Accordion> 
}
    </>
  );
}