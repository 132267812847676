import axios from "axios";

const API_URL = "https://api.tomek.betelpl.bdl.pl"; //"http://fortum.local/";

export function getCzujnikiFromApi() {

        return axios
          .get(API_URL)
          .then((response) => {

            let czujniki = {};

              czujniki.lokalizacje = [];

              for (const property in response.data) {

                if (property == 'items') {

                  for (const property in response.data.items) {

    
                    let temp = {
                        lokalizacjaId: property,
                        punkty: response.data.items[property]
                    };
    
        
                    czujniki.lokalizacje.push(temp);
    
                  }

                } else {

                  czujniki[property] = response.data[property];

                };

              }
    
              return czujniki;


          })
          .catch((error) => error.code);


  }


export const getCzujnikiFromApi1 = async() => {


        await axios.get(API_URL)
            .then(data => {
              //console.log(data.data);
                
              let czujniki = {};

              czujniki.punktyArray = [];
    
              for (const property in data.data.punkty) {
    
                let temp = data.data.punkty[property];
                temp.lokalizacjaId = property;
    
                czujniki.punktyArray.push(temp);

              }

              return czujniki;

            })
            .catch(error => console.log(error));


};

