
import {
  Stack,

} from '@chakra-ui/react'

import LoginForm from './../components/LoginForm'
import SliderMarkExample from './../components/Slider'

function FormLogin() {


  return (
    <>

  <SliderMarkExample min = {10}  max = {50} default = {25} />

</>
  )
}

export default FormLogin;
