import React, { useState } from "react";
import {
  Box,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Button,
  Center,
  Text
} from '@chakra-ui/react'


import { NavLink } from "react-router-dom"
import { getCzujnik } from "./localStorage";


export default function OdczytItem(props) {


    const czujnikDetails = getCzujnik(props.item.id);

    return (
      <AccordionItem key={czujnikDetails.ajdiPunktu}>
    <h2>
    <AccordionButton _expanded={{ bg: 'tomato', color: 'white' }}>
        <Box as='span' flex='1' textAlign='left'>
          Czujnik ID: <strong>{props.item.id}</strong>
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel p={1}>
    <Box p='2' my='4' mx='0' border='1px' borderColor='gray.200' bg='white' rounded='md' key={czujnikDetails.ajdiPunktu}>
        <h3>ID: <strong>{czujnikDetails.ajdiPunktu}</strong></h3>
          <p>
          {czujnikDetails.opisPunktu}
          <br></br><small>Lokalizacja: <strong>{czujnikDetails.lokalizacjaPunktu}</strong></small>
          <br></br><small>Kontrola: <strong>{czujnikDetails.kontrola}</strong></small>
          <br></br><small>Jednostka: <strong>{czujnikDetails.jednostka?czujnikDetails.jednostka:'-'}</strong></small>
          </p>
          
          <p align='center'>
            <strong>Odczyt</strong>
          </p>
          
          {(props.item.data.switch !== undefined)?props.item.data.switch?<Text p={2} align='center' color='white' bg='green'><strong>Czujnik działa poprawnie</strong></Text>:<Text p={2} align='center' color='white' bg='red'><strong>Czujnik działa niepoprawnie</strong></Text>:''}
          {(props.item.data.value !== undefined)?<Text p={2} align='center' color='white' bg='green'><strong>{props.item.data.value} {czujnikDetails.jednostka}</strong></Text>:''}
          {(props.item.data.overlimit !== undefined)?<Text p={2} align='center' color='white' bg='red'><strong>Odczyt poza zakresem ({czujnikDetails.wartosc}-{czujnikDetails.zakres} {czujnikDetails.jednostka})</strong></Text>:''}
          
          <Center my={3}>
          <NavLink to={'/czujnik/'+czujnikDetails.ajdiPunktu} state={{ id: czujnikDetails.ajdiPunktu }}><Button>Popraw</Button></NavLink>
          </Center>
        </Box>
    </AccordionPanel>
  </AccordionItem>
    )
  }

