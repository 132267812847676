'use client'

import {useRef} from 'react';
import { Outlet, NavLink } from "react-router-dom"
import { HamburgerIcon } from '@chakra-ui/icons'
import {
  ChakraProvider,
  Box,
  Flex,
  Avatar,
  HStack,
  Text,
  Image,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
  useColorModeValue,
  Stack,
  Switch
} from '@chakra-ui/react'
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react'
import NfcButton from '../components/NfcButton'
import useNetworkStatus from './../hooks/useNetworkStatus'
import NetworkStatus from './../components/NetworkStatus'
import metadata from './../metadata.json'
import { deleteOdczyty } from '../components/localStorage';

interface Props {
  title: string,
  href: string
}

const Links = [
  {
    key: 1,
    title: 'Start',
    href: '/'
  },
  {
    title: 'Czujniki',
    href: '/czujniki'
  },
  {
    title: 'Odczyty',
    href: '/odczyty'
  },
  {
    title: 'Form',
    href: '/form'
  },
  {
    title: 'Form Login',
    href: '/form-login'
  },
  {
    title: 'NFC Scan',
    href: '/nfc'
  }
];

const NavLink1 = (props: Props) => {
  //const { children } = props

  return (
    <Box
      as="a"
      px={2}
      py={1}
      rounded={'md'}
      _hover={{
        textDecoration: 'none',
        bg: useColorModeValue('gray.200', 'gray.700'),
      }}
      href={props.href}>
      {props.title}
    </Box>
  )
}

function DrawerMain() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = useRef<HTMLButtonElement>(null)

  return (
    <>
      <Button ref={btnRef} rightIcon={<HamburgerIcon />} colorScheme='teal' onClick={onOpen}>
        MENU
      </Button>
      <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Menu</DrawerHeader>

          <DrawerBody>
            <Box textAlign="center" p={2} my={5}>

            <NavLink to={'/'}><Button mt={5} size='lg' colorScheme='blue' onClick={onClose}>Start</Button></NavLink>
  <br></br><NavLink to={'/odczyty/'}><Button mt={5} size='lg' colorScheme='blue' onClick={onClose}>Odczyty</Button></NavLink>
  <br></br><NavLink to={'/czujniki/'}><Button mt={5} size='lg' colorScheme='teal' onClick={onClose}>Czujniki</Button></NavLink>
  
  </Box>
          </DrawerBody>


        </DrawerContent>
      </Drawer>
    </>
  )
}

export default function LayoutNavbarDrawer(props: any) {

  

  return (
    <>
      <ChakraProvider>
      
      <Box bg={useColorModeValue('gray.100', 'gray.900')} px={4}>
        <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
        
          <HStack spacing={8} alignItems={'center'}>
            <Box><Image
    boxSize='50px'
    objectFit='cover'
    src='fortum.png'
    alt='Dan Abramov'
  /></Box>
            <HStack as={'nav'} spacing={4} display={{ base: 'flex', md: 'flex' }}>
            <DrawerMain></DrawerMain>
            </HStack>
          </HStack>
          <Flex alignItems={'center'}>

            <Switch id='email-alerts1' margin={4} isChecked={useNetworkStatus().isOnline} />
            <Menu>
              <MenuButton
                as={Button}
                rounded={'full'}
                variant={'link'}
                cursor={'pointer'}
                minW={0}>
                <Avatar
                  size={'sm'}
                  src={
                    'https://images.unsplash.com/photo-1493666438817-866a91353ca9?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9'
                  }
                />
              </MenuButton>
              <MenuList>
                <MenuItem>Link 1</MenuItem>
                <MenuItem>Link 2</MenuItem>
                <MenuDivider />
                <MenuItem>Link 3</MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </Flex>
      </Box>

      <Box p={4} bg='gray.50'><Outlet /></Box>
      <Box textAlign="center" p={2} mt={3}>
        {`Version ${metadata.buildMajor}.${metadata.buildMinor}.${metadata.buildRevision} ${metadata.buildTag}`}
      </Box>
      </ChakraProvider>
    </>
  )
}