import React, { useState } from "react";
import {
  Box,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Button,
  Center
} from '@chakra-ui/react'

import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react'

import { NavLink } from "react-router-dom"
import { getCzujnik } from "./localStorage";


export default function CzujnikItem(props) {


    return (
      <AccordionItem>
    <h2>
    <AccordionButton _expanded={{ bg: 'tomato', color: 'white' }}>
        <Box as='span' flex='1' textAlign='left'>
          Punkt: <strong>{props.item.lokalizacjaId}</strong> ({props.item.punkty.length} szt.)
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel p={1}>
      {RowCzujniki(props.item.punkty)}
    </AccordionPanel>
  </AccordionItem>
    )
  }

  const RowCzujniki = (items) => {

    return (
      <>
      {items.map((item, index) => (
        <Box p='2' my='4' mx='0' border='1px' borderColor='gray.200' bg='white' rounded='md' key={item.ajdiPunktu}>
        <h3>ID: <strong>{item.ajdiPunktu}</strong></h3>
          <p>
          {item.opisPunktu}
          <br></br><small>Lokalizacja: <strong>{item.lokalizacjaPunktu}</strong></small>
          <br></br><small>Kontrola: <strong>{item.kontrola}</strong></small>
          <br></br><small>Jednostka: <strong>{item.jednostka?item.jednostka:'-'}</strong></small>
          </p>
          <Center my={3}>
          <NavLink to={'/czujnik/'+item.ajdiPunktu} state={{ id: item.ajdiPunktu }}><Button>Odczytaj</Button></NavLink>
          </Center>
        </Box>
      ))}

      
        </>
    )
  }
