import React, { useState } from "react";
import {
  Box,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Button,
  Text
} from '@chakra-ui/react'

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Spinner
} from '@chakra-ui/react'

import { useNavigate } from 'react-router-dom';

import { useDisclosure } from '@chakra-ui/react'

import { NavLink } from "react-router-dom"
import { getCzujnik, getOdczyt } from "./localStorage";


export default function NfcButton() {

  const navigate = useNavigate();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [nfcText, setnfcText] = useState('');
  const [nfcIsRead, setNfcIsRead] = useState(false);
  const [nfcId, setNfcId] = useState<number>(0);
  const [canOdczyt, setCanOdczyt] = useState(false);

  let czujnik = null;

  const abortController = new AbortController();
  abortController.signal.onabort = event => {
    // All NFC operations have been aborted.
    
  };

  const nfc = () => {
    if ('NDEFReader' in window) {
      const ndef = new NDEFReader();

      setNfcIsRead(true);

      var textTemp = '';

      ndef.scan({signal: abortController.signal}).then(() => {
        console.log("Scan started successfully.");
        textTemp += ';Scan started successfully.';
        setnfcText(textTemp);
        ndef.onreading = event => {

          let nfcIdTemp = 0;

          const decoder = new TextDecoder();
          
          const message = event.message;
          for (const record of message.records) {
            console.log("Record type:  " + record.recordType);
            console.log("MIME type:    " + record.mediaType);
            console.log("Record id:    " + record.id);
  
            //textTemp += ';' + record.recordType;
            
            setnfcText(nfcText + ';' + textTemp);

            let readTemp = decoder.decode(record.data);
            
            
            switch (record.recordType) {
              case "text": 
                if (record.data !== undefined) {
                  nfcIdTemp = parseInt(readTemp);
                } 
                break;
              case "url":
                if (record.data !== undefined) {
                  textTemp += '; Url: ' + readTemp;
                  let urlParams = new URLSearchParams(readTemp.split('?')[1]);
                  let paramsId = urlParams.get('id');
                  textTemp += '; ParamsId: ' + paramsId;
                  if (paramsId) {
                    nfcIdTemp = parseInt(paramsId);
                  }
                  
                }
                break;
              default:
                // TODO: Handle other records with record data.
            }
            
            czujnik = getCzujnik(nfcIdTemp);
            textTemp += '; OdczytNFC: ' + nfcIdTemp;
        
            if (czujnik) {
              textTemp += ';Czujnik: ' + record.recordType + ': ' + decoder.decode(record.data);
              setCanOdczyt(true); 
              setNfcIsRead(false);         
            } else {
              textTemp += ';Czujnik nie znaleziony: ' + record.recordType + ': ' + decoder.decode(record.data);
              
            }
            setnfcText(textTemp);
            setNfcId(nfcIdTemp);
          }

          
          
        };
      }).catch((error: any) => {
        console.log(`Error! Scan failed to start: ${error}.`);
        textTemp += ';Error! Scan failed to start';
        setnfcText(textTemp);
      });

      



    } else {
      console.log('a:'+nfcText);
      setnfcText(nfcText + ';Brak NFC');
    }

    
    
  }
  

    return (
      <>
      <Button size='lg' my={3} colorScheme="green" isDisabled={!('NDEFReader' in window)} onClick={() => {
        
        onOpen();
        nfc();

        

      }}>Skanuj NFC</Button>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Skanowanie tokena NFC</ModalHeader>
          <ModalBody pb={6}>
            <Box textAlign="center">
              {nfcIsRead &&
          <Spinner
  thickness='4px'
  speed='0.65s'
  emptyColor='gray.200'
  color='blue.500'
  size='xl'
/>}
{canOdczyt && <Text>
  Odczytano czujnik ID: {nfcId}
  <br></br><strong>Zabierz telefon od tokena NFC</strong>
  </Text>}
  
        </Box>
        {(nfcText == '') && <Box>Logi: {nfcText}</Box>}
          </ModalBody>

          <ModalFooter textAlign="center">
            <Button colorScheme='blue' mr={3} isDisabled={!canOdczyt} onClick={() => {

                abortController.abort();
                onClose();
                navigate('/czujnik/'+nfcId);
            }}>
              Odczyt
            </Button>
            <Button onClick={() => {
              abortController.abort();
              onClose();
              setNfcIsRead(false);
            }}>Anuluj</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      </>
    )
}

