import React, { useEffect } from "react";
import { NavLink } from "react-router-dom"
import {useState} from 'react';

import {
  Accordion
} from '@chakra-ui/react'
import { Box, Icon, Link, Text, Button, Heading } from "@chakra-ui/react";
import {getCzujnikiFromApi} from "../components/api";
import {saveCzujniki, getCzujniki} from "../components/localStorage";
import CzujnikItem from "../components/CzujnikItem";

import {
  ArrowForwardIcon
} from '@chakra-ui/icons'


export default function Czujniki() {

  /*
  const [czujnikiItems, setCzujnikiItems] = useState(
    {
      "data": '2024-11-14',
      "punktyArray": [
        
      ]
  });
  */

  const [czujnikiItems, setCzujnikiItems] = useState(null);

  useEffect(() => {

    setCzujnikiItems(getCzujniki());

  }, []);

  const TableCzujniki = (items) => {

    if (!items) {
      return;
    }

    //console.log(items);
    return (

      
      <Accordion allowToggle>
      {items.lokalizacje.map((item, index) => (
        <CzujnikItem item={item}></CzujnikItem>
       
      ))}
      </Accordion> 
    )
  }

  return (
    <>

        <Box textAlign="center" p={2} mt={3}>
        <Button 
    rightIcon={<ArrowForwardIcon />} 
    colorScheme='teal' 
    variant='outline'
    onClick={() => {
      getCzujnikiFromApi().then((data) => { 
        saveCzujniki(data);
        setCzujnikiItems(getCzujniki());
      });

    }}
  >
    Pobierz listę czujników
  </Button>
  </Box>
    <Heading as='h1' textAlign="center" my={5}>
          Czujniki: {czujnikiItems && czujnikiItems.trasa}
        </Heading>
        {TableCzujniki(czujnikiItems)}
    </>
  );
}