import { useLocalStorage } from "@uidotdev/usehooks";

export function saveCzujniki(data) {

    const stringifiedValue = JSON.stringify(data);

    localStorage.setItem('czujniki', stringifiedValue);

}

export function getCzujniki() {

    return JSON.parse(localStorage.getItem('czujniki'));

}

export function getCzujnik(id) {

    let czujniki = JSON.parse(localStorage.getItem('czujniki'));
    let czujnik = null;


    if (czujniki) {

        czujniki.lokalizacje.map((item, index) => {

            item.punkty.map((subitem, subindex) => {

                if (subitem.ajdiPunktu == id) {

                    return czujnik = subitem;

                }

            })

        })

    }


    return czujnik;


}

export function saveOdczyt(id, data) {



    let odczyty = getOdczyty();

    if (odczyty == null) {

        odczyty = {
            dzien: '2024-11-19',
            punkty: [
                {
                    id: id,
                    data: data
                }
            ]
        }

    } else {

        let isPunkt = false;

        for (let i = 0; i < odczyty.punkty.length; i++) {

            if (odczyty.punkty[i].id == id) {

                odczyty.punkty[i].data = data;
                isPunkt = true;

            }

        }

        if (!isPunkt) {

            odczyty.punkty.push(
                {
                    id: id,
                    data: data
                }
            )

        }

    }

    const stringifiedValue = JSON.stringify(odczyty);

    localStorage.setItem('odczyty', stringifiedValue);

}

export function getOdczyty() {

    return JSON.parse(localStorage.getItem('odczyty'));

}

export function getOdczyt(id) {

    let odczyty = getOdczyty();

    if (odczyty && odczyty.punkty) {

        for (let i = 0; i < odczyty.punkty.length; i++) {

            if (odczyty.punkty[i].id == id) {

                return odczyty.punkty[i];

            }

        }
    }

    return null;

}

export function deleteOdczyty() {
    localStorage.removeItem('odczyty');
}