import React, { useState } from "react";
import {
    Box,
    Slider,
    SliderMark,
    SliderTrack,
    SliderFilledTrack,
    SliderThumb,
  } from "@chakra-ui/react";

 interface ISliderProps {
    min: number,
    max: number,
    default: number
}


function SliderMarkExample(props: ISliderProps) {
    const [sliderValue, setSliderValue] = useState(50)
  
    const labelStyles = {
      mt: '2',
      ml: '-2.5',
      fontSize: 'sm',
    }

    let content = [];
    for (let i = 0; i < 5; i++) {
      content.push(<SliderMark value={10+i*10} {...labelStyles}>{10+i*10}%</SliderMark>);
    }
  
    return (
      <Box p={4} pt={6}>
        <p>Max: {props.max}</p>
        <Slider aria-label='slider-ex-6' onChange={(val: any) => setSliderValue(val)} step={1} min={props.min} max={props.max} defaultValue={props.default} >
            {content}
          <SliderMark
            value={sliderValue}
            textAlign='center'
            bg='blue.500'
            color='white'
            mt='-10'
            ml='-5'
            w='12'
          >
            {sliderValue}%
          </SliderMark>
          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>
          <SliderThumb />
        </Slider>
      </Box>
    )
  }


  export default SliderMarkExample;